import React from "react";

const Text = (props) => {
  return (
    <span
      style={{
        backgroundColor: "#e9e7e7",
        color: "black",
        fontWeight: 500,
        padding: 3,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
        fontSize: 14,
      }}
    >
      {props.children}
    </span>
  );
};

export const TextSmall = (props) => {
  return (
    <span
      style={{
        color: "black",
        fontWeight: 500,
        padding: 3,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
        fontSize: 12,
      }}
    >
      {props.children}
    </span>
  );
};

export default Text;
