import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react'
import { fontSize } from '../HomePage';
import { Table } from 'react-bootstrap';
import { Loader } from '../../../components/Loader/Loader';
import jsPDF from 'jspdf';
import Text, { TextSmall } from '../../../components/Forms/Text';

const OrderDetails = (props) => {

    const { isOrderDetailsLoading,
        orderDetails,
        dateFormat,
        selectedOrderNo,
        selectedCompany,
        companiesList,
        clientname,
        now
    } = props;

    const orderDetailsPdf = () => {
        const companyName = companiesList.find(
            (data) => data.comCode === selectedCompany.value
        ).companyName;
        const doc = new jsPDF("l", "pt", "legal");
        doc.setFontSize(12);
        doc.text(`${clientname}`, 50, 19);

        doc.setFontSize(10);
        doc.text(`Supplier Name : ${"  "} ${orderDetails[0].supName}`, 50, 32);
        doc.text(
            `Order No :${"  "}${selectedOrderNo && selectedOrderNo.orderNo}`,
            50,
            45
        );
        doc.text(
            `Order Date:${"  "} ${selectedOrderNo && dateFormat(selectedOrderNo.orderDt, "dd-mm-yyyy")
            }`,
            50,
            58
        );
        doc.autoTable({ html: "#orderlist", startY: 70 });
        doc.text(
            `www.amruthstockandsale.com ${" "} ${dateFormat(now, "isoDateTime")}`,
            550,
            550
        );
        // doc.autoTable({ startX: 120, startY: 150 });
        doc.save("orderDetails.pdf");
    };


    return (
        <>
            <div className="col-lg-12">
                {isOrderDetailsLoading && <Loader />}
                <div
                    className="col-md-12"
                    style={{
                        backgroundColor: "#fcfcfc",
                        padding: "8px",
                        borderRadius: "4px",
                        // margin: "0px",
                        marginTop: "10px",
                    }}
                >
                    <div className="row">
                        <div className="col-lg-8">
                            <h5 style={{ color: '#007bff' }}>Detailed Order Table</h5>
                        </div>
                        <div
                            className="col-lg-4 d-flex justify-content-end"
                            style={{ backgroundColor: "" }}
                        >
                            <Button
                                // className="btn btn-info btn-sm "
                                style={{
                                    backgroundColor: "#17a2b8",
                                    color: "white"
                                }}
                                onClick={() => {
                                    orderDetailsPdf();
                                }}
                                icon={<DownloadOutlined />}
                            >
                                Download
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <TextSmall>Supplier Name</TextSmall>
                            <br />
                            <Text>
                                {selectedCompany.label}
                            </Text>
                        </div>

                        <div className="col-md-3">
                            <TextSmall>Order no.</TextSmall><br />
                            <Text>&nbsp;&nbsp;{selectedOrderNo && selectedOrderNo.orderNo}</Text>
                        </div>
                        <div className="col-md-3">
                            <TextSmall>Order Date</TextSmall>
                            <br />

                            <Text>
                                {selectedOrderNo &&
                                    dateFormat(selectedOrderNo.orderDt, "dd-mm-yyyy")}
                            </Text>
                        </div>
                    </div>
                </div>

                <div
                    className="col-md-12"
                    style={{
                        marginTop: "18px",
                        backgroundColor: "#fcfcfc",
                        borderRadius: "4px",
                    }}
                >
                    <div style={{ padding: "10px" }}>
                        <Table
                            id="orderlist"
                            bordered
                            style={{ marginBottom: 100 }}
                            hover
                            striped
                            responsive
                            size="sm"
                        >
                            <thead
                                style={{
                                    fontSize: fontSize,
                                    color: "#3F1FB7",
                                    backgroundColor: "#86DEFF",
                                    textAlign: "right",
                                }}
                            >
                                <tr>
                                    <th style={{ textAlign: "left", padding: "4px" }}>
                                        Product Name
                                    </th>
                                    <th style={{}}>Quantity</th>
                                    <th style={{}}>Free</th>
                                    <th style={{ textAlign: "left" }}>Pack</th>
                                    <th style={{ textAlign: "left" }}>Remark</th>
                                    <th style={{}}>Dispatch Date</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "right" }}>
                                {orderDetails.map((data, i) => (
                                    <tr key={i}>
                                        <td
                                            style={{
                                                padding: "4px",
                                                textAlign: "left",
                                                fontSize: fontSize,
                                            }}
                                        >
                                            {data.prodName}
                                        </td>
                                        <td style={{ padding: "1px", fontSize: fontSize }}>
                                            {data.qty1}
                                        </td>
                                        <td style={{
                                            // padding: "1px",
                                            fontSize: fontSize
                                        }}>
                                            {data.free1}
                                        </td>
                                        <td style={{ textAlign: "left", fontSize: fontSize }}>
                                            {data.pack}
                                        </td>
                                        <td style={{ textAlign: "left", minWidth: 150, fontSize: fontSize }}>
                                            {data.remark}
                                        </td>
                                        <td style={{ padding: "1px", maxWidth: 170, width: 147, fontSize: fontSize }}>
                                            {/* {data.dispDt1} */}
                                            {dateFormat(data.dispDt1, "dd-mm-yyyy")}
                                        </td>
                                    </tr>
                                ))}
                                {/* <tr>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr> */}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetails;