import React from "react";

const Footer = ({ website }) => {
  return (
    <section
      className="footer"
      style={{
        position: "fixed",
        bottom: 0,
        zIndex: 10,
      }}
    >
      <div className="  footer-container">
        <div className="footer-item">
          {website} by{" "}
          <a href="https://www.relysoft.in/" target="_blank">
            Reliable Software
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
