export const MappingHeader = {
  Flag: {
    name: "Flag",
    style: {

      maxWidth: 100
    },
    headerStyle: {
    }
  },

  "Product Name": {
    name: "Product Name",
    style: {
      width: window.innerWidth < 720 ? 135 : 300,
    },
    headerStyle: {
      // backgroundColor: "#86DEFF",
    }
  },

  Pack: {
    name: "Pack", style: {
    },
    headerStyle: {
    }
  },
  rate: {
    name: "Rate", style: {
    },
    headerStyle: {
    }
  },
  opening: {
    name: "Opening", style: {}, headerStyle: {
    }
  },
  prevSale: {
    name: "Prev Sale", style: {}, headerStyle: {
    }
  },
  receipts: {
    name: "Receipts", style: {}, headerStyle: {

    }
  },
  sq1: {
    name: "Sales", style: {}, headerStyle: {

    }
  },
  sq2: {
    name: "sq2", style: {}, headerStyle: {

    }
  },
  sq3: {
    name: "sq3", style: {}, headerStyle: {

    }
  },
  sq4: {
    name: "sq4", style: {}, headerStyle: {

    }
  },
  sqTot: {
    name: "sqTot", style: {}, headerStyle: {

    }
  },
  saleVal: {
    name: "saleVal", style: {}, headerStyle: {

    }
  },
  clq1: {
    name: "clq1", style: {}, headerStyle: {

    }
  },
  clq2: {
    name: "clq2", style: {}, headerStyle: {

    }
  },
  clq3: {
    name: "clq3", style: {}, headerStyle: {

    }
  },
  clq4: {
    name: "clq4", style: {}, headerStyle: {

    }
  },
  clqtot: {
    name: "clqtot", style: {}, headerStyle: {

    }
  },
  clVal: {
    name: "clVal", style: {}, headerStyle: {

    }
  },
  dtf: {
    name: "dtf", style: {}, headerStyle: {

    }
  },
  dtt: {
    name: "dtt", style: {}, headerStyle: {

    }
  },
  transit: {
    name: "transit", style: {}, headerStyle: {

    }
  },
  transfer: {
    name: "transfer", style: {}, headerStyle: {

    }
  },
  insti: {
    name: "insti", style: {}, headerStyle: {

    }
  },
  instiV: {
    name: "instiV", style: {}, headerStyle: {

    }
  },
  order1: {
    name: "order1", style: {}, headerStyle: {

    }
  },
  order2: {
    name: "order2", style: {}, headerStyle: {

    }
  },
  order3: {
    name: "order3", style: {}, headerStyle: {

    }
  },
  adjQty: {
    name: "AdjQty", style: {}, headerStyle: {

    }
  },
};



export const SummaryMapping = {
  clVal: {
    name: "clVal",
    style: {}, headerStyle: {}
  },
  instiV: {
    name: "instiV",
    style: {}, headerStyle: {}
  },
  opening: {
    name: "Opening",
    style: {}, headerStyle: {}
  },
  prevSale: {
    name: "Previous Sale",
    style: {}, headerStyle: {}
  },
  receipts: {
    name: "Receipts",
    style: {}, headerStyle: {}
  },
  saleVal: {
    name: "Sale Value",
    style: {}, headerStyle: {}
  },
};