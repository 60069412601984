import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import auth from "../../images/auth.png";
import { message } from "antd";
import { env } from "../../env";
import { Card, Col, Row } from "react-bootstrap";
import { SendMessageToCSharp } from "./test1";
import { LoginOutlined } from "@ant-design/icons";
import { Loader } from "../Loader/Loader";

export const wcClient = "5465977";
export const storageData = "user-info";

function LoginForm(props) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    clientid: "",
    username: "",
    password: "",
  });

  const loggedInItems =
    JSON.parse(localStorage.getItem("logged-details")) || [];

  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(false);

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
    // navigate("/", { replace: true });
    // setIsLoggedin(false);
  };
  // Show the list of LogidIn items from local storage

  const onSubmit = (form = null) => {
    const reqData = form ? form : data;

    let item = {
      userId: reqData.username,
      password: reqData.password,
      wclientid: reqData.clientid,
    };
    setIsAuthLoading(true);
    fetch(env.REACT_APP_BASE_URL + "/Com/Authenticate/AuthTokenCom/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.isSuccess) {
          console.log(result);
          navigate("/home", { replace: true });
          const userInfo = { ...result, clientid: reqData.clientid };
          localStorage.setItem("user-info", JSON.stringify(userInfo));

          // Add userId and password to the list of items in localStorage
          let litemsTemp =
            JSON.parse(localStorage.getItem("logged-details")) || [];
          const litems = litemsTemp.filter(
            (c) => c.clientid !== reqData.clientid
          );
          litems.push({
            clientName: userInfo.clientName,
            place: userInfo.place,
            userId: reqData.username,
            password: reqData.password,
            clientid: reqData.clientid,
          });
          if (window.innerWidth < 720) {
            localStorage.setItem("logged-details", JSON.stringify(litems));
          }

          setIsLoggedin(true);
        } else {
          message.error(
            "Could Not Login " + (result.reason ? result.reason : "")
          );
          throw result;
        }
      })
      .catch((err) => {
        // message.error("Failed To Login")
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.error(err.response);
          //  logout();
          // ToastError("Token has expired Please refresh");
        }
      })
      .finally(() => {
        setIsAuthLoading(false);
      });
  };

  return (
    <>
      <div className="">
        <Row className="w-100">
          {isAuthLoading && <Loader alignItems="center" />}
          {window.innerWidth > 768 && (
            <Col
              lg={8}
              style={{
                backgroundColor: "",
              }}
            >
              <div
                style={{
                  padding: 12,
                }}
              >
                {/* <img
                  id="auth"
                  src={auth}
                  height={"auto"}
                  width={"100%"}
                  alt="Info "
                /> */}
              </div>
            </Col>
          )}
          <Col lg={4} className="p-5">
            {window.innerWidth > 768 ? (
              <h2 className="title">amruthstockandsale.com</h2>
            ) : (
              <h4 className="title">amruthstockandsale.com</h4>
            )}
            <h6 className="mt-4">Login</h6>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="form-group">
                <input
                  type="text"
                  name="clientid"
                  placeholder="Distributor ID"
                  onChange={changeHandler}
                  className="form-control form-control-lg"
                  value={data.clientid}
                />
              </div>
              <br />
              <input
                type="text"
                name="username"
                placeholder="User Id"
                onChange={changeHandler}
                className="form-control form-control-lg"
                value={data.username}
              />
              <br />
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={changeHandler}
                className="form-control form-control-lg"
                value={data.password}
              />
              <br />
              <button
                onClick={(e) => onSubmit()}
                className="btn btn-info btn-lg mt-2 d-flex align-items-center "
              >
                <LoginOutlined className="mr-2" />
                Login
              </button>

              {/* <button
                onClick={() => {
                  SendMessageToCSharp("Button clicked!");
                }}
                // hidden //Will Not Show the button
              >
                Click me! Testing Post Build
              </button> */}
            </form>
            <div>
              <ul className="mt-3">
                {loggedInItems.map((item, index) => (
                  <li
                    key={index}
                    className="  text-primary login-link"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setData({
                        clientid: item.clientid,
                        username: item.userId,
                        password: item.password,
                      });
                      onSubmit({
                        username: item.userId,
                        password: item.password,
                        clientid: item.clientid,
                      });
                    }}
                  >
                    {item.clientName}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>

        {window.innerWidth < 768 && (
          <div className=" " style={{ backgroundColor: "" }}>
            {/* <img
              id="auth"
              src={auth}
              height={"auto"}
              width={"100%"}
              alt="Info "
            /> */}
          </div>
        )}
      </div>
    </>
  );
}

export default LoginForm;
