import React from "react";
import { fontSize } from "../HomePage";
import { SummaryMapping } from "../data/MappingHeader";
const SummaryTable = (props) => {
  const { summary } = props;
  return (
    <div className="col-lg-12" style={{ marginTop: "2px" }}>
      <div
        className=""
        style={{
          backgroundColor: "#fcfcfc",
          borderRadius: "4px",
          padding: "5px",
          marginTop: "5px",
          // margin: "6px",
        }}
      >
        <h8
          style={{
            color: "Blue",
            marginTop: "5px",
          }}
        >
          Summary
        </h8>
        <div
          style={{
            width: "100%",
            overflow: "auto",
            // marginBottom: 100,
          }}
        >
          <table className="table table-condensed table-striped table-bordered">
            <thead
              style={{
                fontSize: fontSize,
                color: "#3F1FB7",
                backgroundColor: "#86DEFF",
                textAlign: "right",
              }}
            >
              <tr
                style={{
                  fontSize: fontSize,
                }}
              >
                {Object.keys(summary)
                  .filter((str) => !str.startsWith("_"))
                  .map((el, ii) => {
                    return (
                      <th
                        key={ii}
                        style={{
                          ...SummaryMapping[el]?.headerStyle,
                        }}
                      >
                        {SummaryMapping[el] ? SummaryMapping[el]?.name : el}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody
              style={{
                fontSize: fontSize,
                textAlign: "right",
              }}
            >
              <tr>
                {Object.keys(summary)
                  .filter((str) => !str.startsWith("_"))
                  .map((el, ii) => {
                    const tdVal = summary[el];
                    return (
                      <td
                        key={ii}
                        style={{
                          ...SummaryMapping[el]?.style,
                        }}
                      >
                        {tdVal}
                      </td>
                    );
                  })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SummaryTable;
