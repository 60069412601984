import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Bowser from "bowser";
import { SendMessageToCSharp } from "./donet";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

/**
 * This function iterates over all of the columns to create a row of header cells
 */
const getHeaderToExport = (gridApi) => {
  const columns = gridApi.getAllDisplayedColumns();

  return columns.map((column, index) => {
    const { field } = column.getColDef();
    const sort = column.getSort();
    // Enables export when row grouping
    const headerName = column.getColDef().headerName ?? field;
    const headerNameUppercase =
      headerName[0].toUpperCase() + headerName.slice(1);
    const headerCell = {
      text: headerNameUppercase + (sort ? ` (${sort})` : ""),

      // styles
      bold: true,
      margin: [0, 6, 0, 0],
      alignment: index >= 3 ? "right" : "left", // Right-align after the third column
    };
    return headerCell;
  });
};

/**
 * This function iterates over all of the rows and columns to create
 * a matrix of cells when pivoting is enabled
 */

const getRowsToExportPivot = (gridApi) => {
  const columns = gridApi.getAllDisplayedColumns();

  const getCellToExport = (column, node) => {
    return {
      text: gridApi.getValue(column, node) ?? "",
      // styles
      ...column.getColDef().cellStyle,
      // alignment: true ? 'right' : 'left', // Right-align after the third column
    };
  };

  const rowsToExport = [];
  gridApi.forEachNodeAfterFilterAndSort((node) => {
    if (node.group) {
      const rowToExport = columns.map((column) =>
        getCellToExport(column, node)
      );
      rowsToExport.push(rowToExport);
    }
  });

  return rowsToExport;
};

/**
 * This function iterates over all of the rows and columns to create
 * a matrix of cells
 */
const getRowsToExport = (gridApi) => {
  //Enables export when pivoting
  if (gridApi.isPivotMode()) {
    return getRowsToExportPivot(gridApi);
  }
  const columns = gridApi.getAllDisplayedColumns();

  const getCellToExport = (column, node) => {
    // console.log("Col iD ", column, node);
    return {
      text: gridApi.getValue(column, node) ?? "",
      // styles
      ...column.getColDef().cellStyle,
      margin: [0, 3, 0, 0],
      alignment: !column.userProvidedColDef.pinned ? "right" : "left", // Right-align after the third column
    };
  };

  const rowsToExport = [];
  gridApi.forEachNodeAfterFilterAndSort((node) => {
    const rowToExport = columns.map((column) => getCellToExport(column, node));
    rowsToExport.push(rowToExport);
  });

  return rowsToExport;
};

// Row colors
const HEADER_ROW_COLOR = "#f8f8f8";
const EVEN_ROW_COLOR = "#fcfcfc";
const ODD_ROW_COLOR = "#fff";

const PDF_INNER_BORDER_COLOR = "#dde2eb";
const PDF_OUTER_BORDER_COLOR = "#babfc7";

const createLayout = (numberOfHeaderRows) => ({
  fillColor: (rowIndex) => {
    if (rowIndex < numberOfHeaderRows) {
      return HEADER_ROW_COLOR;
    }
    return rowIndex % 2 === 0 ? EVEN_ROW_COLOR : ODD_ROW_COLOR;
  },
  //vLineHeight not used here.
  vLineWidth: (rowIndex, node) =>
    rowIndex === 0 || rowIndex === node.table.widths.length ? 1 : 0,
  hLineColor: (rowIndex, node) =>
    rowIndex === 0 || rowIndex === node.table.body.length
      ? PDF_OUTER_BORDER_COLOR
      : PDF_INNER_BORDER_COLOR,
  vLineColor: (rowIndex, node) =>
    rowIndex === 0 || rowIndex === node.table.widths.length
      ? PDF_OUTER_BORDER_COLOR
      : PDF_INNER_BORDER_COLOR,
});

/**
 * Returns a pdfMake shaped config for export, for more information
 * regarding pdfMake configuration, please see the pdfMake documentation.
 */
const getDocument = (gridApi, sendData) => {
  const columns = gridApi.getAllDisplayedColumns();

  const mainHeader = {
    text: sendData.topHeader,
    fontSize: 15,
    bold: true,
    margin: [0, 0, 0, 10], // Adjust margins as needed
    color: "#2980ba",
  };

  const subHeader1 = {
    color: "#2980ba",
    text: sendData.secondHeader,
    fontSize: 11,
    bold: true,
    margin: [0, 0, 0, 10], // Adjust margins as needed
  };

  const subHeader2 = {
    color: "#2980ba",
    text: sendData.thirdHeader,
    fontSize: 11,
    bold: true,
    margin: [0, 0, 0, 10], // Adjust margins as needed
  };
  const footerText = {
    color: "#2980ba",
    text: sendData.footerText,
    fontSize: 11,
    alignment: "right",
    bold: true,
    margin: [0, 20, 10, 10], // Adjust margins as needed
  };

  const headerRow = getHeaderToExport(gridApi);
  const rows = getRowsToExport(gridApi);

  // Define the widths for each column
  const columnWidths = columns.map(() => `${93 / columns.length}%`);
  // Increase the width of the second column (index 1)
  columnWidths[1] = 100; // You can set a specific width like '100' or '200' instead of 'auto'

  const pageNumber = (currentPage, pageCount) => {
    return {
      text: `Page ${currentPage} of ${pageCount}`,
      alignment: "right",
      fontSize: 10,
      margin: [10, 10],
      color: "#2980ba",
    };
  };

  return {
    pageOrientation: "landscape", // can also be 'portrait'
    pageSize: "LEGAL",
    content: [
      mainHeader,
      subHeader1,
      subHeader2,
      {
        table: {
          // the number of header rows
          headerRows: 1,

          // the width of each column, can be an array of widths
          widths: columnWidths,

          // all the rows to display, including the header rows
          body: [headerRow, ...rows],

          // Header row is 40px, other rows are 15px
          heights: (rowIndex) => (rowIndex === 0 ? 20 : 15),
        },
        layout: createLayout(1),
        fontSize: 8,
      },
      footerText,
    ],
    pageMargins: [10, 10, 10, 40],
    fontSize: 5, // Set the font size for the entire table
    footer: pageNumber,
  };
};

export const exportToPDF = (gridApi, sendData) => {
  const doc = getDocument(gridApi, sendData);
  const pdfDocGenerator = pdfMake.createPdf(doc);
  const browser = Bowser.getParser(window.navigator.userAgent);

  if (window?.HybridWebView) {
    pdfDocGenerator.getDataUrl((dataUrl) => {
      const base64String = dataUrl.split(",")[1];
      const filedata = { filetask: "pdf", data: base64String };
      console.log("filedata", JSON.stringify(filedata));
      SendMessageToCSharp(JSON.stringify(filedata));
    });
  } else {
    pdfDocGenerator.download();
  }
};

//   platform = navigator.platform;
//     }

//   const browser = Bowser.getParser(window.navigator.userAgent);
//   const platformType = browser.getPlatform();
//   const browserName = browser.getBrowserName();
//   const pl = browser.getPlatformType();

//   alert("platform " + browser.getOSName() + " \n browser " + browserName);
//   alert(JSON.stringify(browser));
//   //   // Check if running in a browser
//   //   if (
//   //     userAgent.indexOf("Chrome") > -1 ||
//     userAgent.indexOf("Firefox") > -1 ||
//     userAgent.indexOf("Safari") ||
//     userAgent.indexOf("Edge") > -1
//   ) {
//     // Download logic for browser
//     alert("browser");
//   }
//   // Check if running in EJl.hybridWebView on Android

//     if (
//     platform === "Android" &&
//     userAgent.indexOf("EJl.hybridWebView") > -1
//   ) {
//     alert("EL Android");
//   }
//   // Check if running in EJl.hybridWebView on Windows
//   else if (
//     platform === "Win32" &&
//     userAgent.indexOf("EJl.hybridWebView") > -1
//   ) {
//     // Download logic for EJl.hybridWebView on Windows
//     alert("EL Windows");
//   }
// };

// export const exportToPDF = (gridApi, sendData) => {
//   const doc = getDocument(gridApi, sendData);

//   ///
//   export const exportToPDF = (gridApi, sendData) => {
//     const doc = getDocument(gridApi, sendData);

//     pdfMake.createPdf(doc).getBuffer((buffer) => {
//       const blob = new Blob([buffer], { type: "application/pdf" });
//       const url = URL.createObjectURL(blob);
//       window.open(url, "_blank");
//     });
//   };

//   ///

//   const pdfData = pdfMake.createPdf(doc).getBuffer();

//   // Convert the Blob to a URL
//   const url = URL.createObjectURL(
//     new Blob([pdfData], { type: "application/pdf" })
//   );

//   // Open the new page
//   window.open(`../../pdfviewer.html?pdfUrl=${encodeURIComponent(url)}`);

//   const pdfWindow = pdfMake.createPdf(doc);
//     pdfWindow.open({}, window);

//   window.onload = function () {
//     const backButton = document.createElement("button");
//     backButton.innerText = "Back";
//     backButton.style.position = "fixed";
//     backButton.style.top = "10px";
//     backButton.style.left = "10px";
//     backButton.addEventListener("click", () => {
//       window.close();
//     });

//     document.body.appendChild(backButton);
//     };
