import React, { useCallback, useEffect, useRef, useState } from "react";
import { MappingHeader } from "../data/MappingHeader";
import { fontSize } from "../HomePage";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import PDF, { jsPDF } from "jspdf";
import { AgGridReact } from "ag-grid-react";
import { exportToPDF } from "../../../components/Aggrid/gridexporter";

const StockAndSalesTable = (props) => {
  const {
    stockAndSale,
    companiesList,
    dateFrom,
    dateTo,
    dateFormat,
    now,
    selectedCompany,
    clientname,
  } = props;

  // const [gridApi, setGridApi] = useState(null);

  const gridRef = useRef(null);

  // const onGridReady = useCallback((params) => {
  //   setGridApi(params.api);

  // }, []);

  const PDF = () => {
    const companyName = companiesList.find(
      (data) => data.comCode === selectedCompany.value
    ).companyName;
    const doc = new jsPDF("l", "pt", "legal");
    // const stock = doc.autoTable({ html: "#stockandsales" });
    doc.setFontSize(12);
    doc.setTextColor("#2980ba");
    doc.text(`${clientname}`, 60, 20);

    doc.setFontSize(8);
    doc.text(`Company Name: ${companyName}`, 60, 32);

    doc.setFontSize(8);
    doc.text(`Stock & Sales Statement from: ${dateFrom} to ${dateTo}`, 60, 44);

    doc.autoTable({
      html: "#stockandsales",
      startY: 60,
      margin: { top: 10, right: 5, bottom: 50, left: 5 },
      // marginBottom: 60,
      fontSize: 8,
      styles: {
        fontSize: 8,
        halign: "right",
      },

      willDrawCell: function (data) {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          doc.setFillColor("#86DEFF");
        }
      },
      didParseCell: (hookData) => {
        if (hookData.section === "head") {
          switch (hookData.column.dataKey) {
            case 1:
              hookData.cell.styles.halign = "left";
              break;
            case 2:
              hookData.cell.styles.halign = "left";
              break;
            case 3:
              hookData.cell.styles.halign = "left";
              break;

            default:
              break;
          }
        }
      },
      // For Right Aligning Cell In PDF
      columnStyles: {
        1: {
          halign: "left",
        },
        2: {
          halign: "left",
        },
        3: {
          halign: "left",
        },
      },
    });

    let finalY = doc.lastAutoTable.finalY; // The y position on the page
    doc.text(
      750,
      finalY + 20,
      `www.amruthstockandsale.com ${" "} ${dateFormat(
        now,
        "dd-mm-yyyy:hh mm TT"
      )}`
    );

    const pageCount = doc.internal.getNumberOfPages();
    console.log({ pageCount });
    for (let index = 1; index <= pageCount; index++) {
      doc.setPage(index);
      doc.text(
        "Page " + String(index) + " of " + String(pageCount),
        750,
        765,
        null,
        null,
        "right"
      );
    }

    doc.save("stock&sales.pdf");
  };

  // set background colour on every row, this is probably bad, should be using CSS classes
  const rowStyle = { background: "white" };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params) => {
    if (params.data._Color) {
      return {
        background: params.data._Color,
        color: "white",
      };
    }
  };

  return (
    <>
      <div className="row" style={{ paddingTop: "5px" }}>
        <div className="col-lg-8" style={{ backgroundColor: "" }}>
          <h7 style={{ color: "#007bff" }}>
            Detailed Stock And Sales Statement
          </h7>
        </div>
        <div
          className="col-lg-4"
          style={{ backgroundColor: "", padding: "5px" }}
        >
          <Button
            type="default"
            // className="pr-5 pl-4   "
            color="#17a2b8"
            style={{
              width: 85,
              float: "right",
              marginRight: "20px",
              // marginRight: "5px",

              backgroundColor: "#17a2b8",
              color: "white",
            }}
            onClick={() => {
              // PDF();

              const companyName = companiesList.find(
                (data) => data.comCode === selectedCompany.value
              ).companyName;
              const sendData = {
                topHeader: clientname,
                secondHeader: `Company Name: ${companyName}`,
                thirdHeader: `Stock & Sales Statement from: ${dateFrom} to ${dateTo}`,
                footerText: `www.amruthstockandsale.com ${" "} ${dateFormat(
                  now,
                  "dd-mm-yyyy:hh mm TT"
                )}`,
              };
              gridRef.current && exportToPDF(gridRef.current?.api, sendData);
            }}
            icon={<DownloadOutlined />}
          >
            {/* Download */}
          </Button>
        </div>
      </div>

      <div className="ag-theme-quartz" style={{ height: 500 }}>
        {/* The AG Grid component */}
        <AgGridReact
          rowData={stockAndSale}
          defaultColDef={{
            suppressMovable: true,
            sortable: false,
          }}
          ref={gridRef}
          // colResizeDefault="shift"
          columnDefs={
            Array.isArray(stockAndSale)
              ? stockAndSale.length > 0 &&
                Object.keys(stockAndSale[0])
                  .filter((str) => !str.startsWith("_"))
                  .map((c, i) => {
                    let pinned = false;
                    if (
                      i === 0 &&
                      window.innerWidth <= 1024 &&
                      c === "Product Name"
                    ) {
                      pinned = true;
                    } else if (i < 3 && window.innerWidth > 1024) {
                      pinned = true;
                    }
                    console.log({ c, pinned });
                    return {
                      field: c,
                      pinned: pinned,
                      // MappingHeader
                      headerClass:
                        i > 2
                          ? "headerStyles ag-right-aligned-header"
                          : "headerStyles",
                      type: i > 2 && "rightAligned",
                      cellStyle: {
                        fontWeight: 500,
                      },
                      width: 120,
                      ...MappingHeader[c]?.style,
                    };
                  })
              : [{}]
          }
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
        />
      </div>

      <div className="p-5"></div>
    </>
  );
};

export default StockAndSalesTable;
