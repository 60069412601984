import React from 'react';
import { Spinner } from 'react-bootstrap';
export const Loader = (props) => {
    console.log(props);
    return (
        <div
            style={{
                zIndex: 5000,
                backgroundColor: "#ffffffad",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: props.alignItems ? props.alignItems : "flex-start",
            }}
        >
            <Spinner className='mt-5' ></Spinner>

        </div>
    );
};
