import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../App.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { jsPDF } from "jspdf";
import { Col, Row, Table } from "react-bootstrap";
import dateFormat from "dateformat";
import { Button, message } from "antd";
import { DownloadOutlined, PoweroffOutlined } from "@ant-design/icons";
import { env } from "../../env";
import "./css/style.css";
import StockAndSalesTable from "./components/StockandsalesTable";
import SummaryTable from "./components/SummaryTable";
import Footer from "./components/Footer";

import "jspdf-autotable";
import { Loader } from "../../components/Loader/Loader";
import OrderDetails from "./components/OrderDetails";
import Text, { TextSmall } from "../../components/Forms/Text";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

const Context = React.createContext({
  name: "Default",
});

const now = new Date();
// dateFormat(now, "isoDateTime");
export const fontSize = "16px";
const typeOptions = [
  {
    value: false,
    label: "Running",
  },
  {
    value: true,
    label: "Monthly",
  },
];

const initialValues = {
  companies: [],
  localUrl: "",
  repCode: null,
  reports: [],
  showProductWiseSale: false,
  wclientid: "",
};

const initialSummary = {};
const website = "amruthstockandsale.com";
function HomePage() {
  let navigate = useNavigate();

  const textFromStorage = JSON.parse(localStorage.getItem("user-info"));
  // console.log(textFromStorage.clientName);
  const clientname = textFromStorage ? textFromStorage.clientName : "";
  const place = textFromStorage ? textFromStorage.clientPlace : "";
  const username = textFromStorage ? textFromStorage.name : "";

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedType, setSelectedType] = useState(typeOptions[0]);

  //   const [showStockandSales, setShowStockandSales] = useState(false);

  const [showData, setShowData] = useState(false);
  const [vieworder, setVieworder] = useState(false);
  // const [data, setData] = useState(initialValues);
  const [companiesList, setCompaniesList] = useState([]);
  const [summary, setSummary] = useState(initialSummary);
  const [stockAndSale, setStockAndSale] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [detailedOrderList, setDetailedOrderList] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderNo, setOrderNo] = useState([]);
  const [dateFrom, setDateFrom] = useState([]);
  const [dateTo, setDateTo] = useState([]);
  const [selectedOrderNo, setSelectedOrderNo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderDetailsLoading, setIsOrderDetailsLoading] = useState(false);
  const [companyIsLoading, setCompanyIsLoading] = useState(false);

  const handleChangeCompany = (selectedComp) => {
    setStockAndSale([]);
    setSummary([]);

    setSelectedCompany(selectedComp);
    setDetailedOrderList(false);
    setVieworder(false);
    setShowData(false);
    // setOrderList([]);
    // setSelectedType(seclectedtype);
  };

  const handleChangeType = (type) => {
    setStockAndSale([]);
    setSummary([]);
    setVieworder(false);
    setShowData(false);
    setOrderDetails([]);
    setSelectedType(type);
  };

  const logout = () => {
    localStorage.removeItem("user-info");
    navigate("/", { replace: true });
    // setIsLoggedin(false);
  };

  const showDataList = useCallback(() => {
    const data = JSON.parse(localStorage.getItem("user-info"));
    console.log(data.code);
    setCompanyIsLoading(true);
    axios
      .post(
        env.REACT_APP_BASE_URL + "/OwnAppConfigs/CompanyLoginConfigWeb/",
        {
          wclientid: data.clientid,
          repCode: data.code,
        },
        { headers: { Authorization: `Bearer ${data.token}` } }
      )
      .then(function (response) {
        console.log(response);
        // console.log("data", response.data.companies[0].companyName);
        const res = response;
        setCompaniesList(res.data);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.error(err.response);
          message.error("Token Expired ");

          setTimeout(() => {
            logout();
          }, 500);
        }
      })
      .finally(() => {
        setCompanyIsLoading(false);
      });
  }, []);

  const showSummary = () => {
    if (!selectedCompany || !selectedCompany.value) {
      message.error("Please Select Company ");
      return;
    }
    const getToken = JSON.parse(localStorage.getItem("user-info"));
    const companyCode = companiesList.find(
      (data) => data.comCode === selectedCompany.value
    ).comCode;

    console.log("company code", companyCode);
    setIsLoading(true);
    axios
      .post(
        env.REACT_APP_BASE_URL + "/StockAndSales/StockAndSale/",
        {
          wclientid: getToken.clientid,
          compCode: companyCode,
          monthly: selectedType.value,
        },
        { headers: { Authorization: `Bearer ${getToken.token}` } }
      )
      .then(function (res) {
        console.log(res);
        const jsonConvert = JSON.parse(JSON.stringify(res.data));
        const response = jsonConvert["stockandsale" + selectedType.label];
        let stockAndSalesDetails = response.map(function (data, i) {
          return data;
        });
        let dateFrom = response.map(function (data, i) {
          return data._dtf;
        });
        let dateTo = response.map(function (data, i) {
          return data._dtt;
        });
        console.log(dateFormat(dateFrom[0], "dd-mm-yyyy"));
        console.log(dateTo);

        setDateFrom(dateFormat(dateFrom[0], "dd-mm-yyyy"));
        setDateTo(dateFormat(dateTo[0], "dd-mm-yyyy"));

        console.log(stockAndSalesDetails);
        setStockAndSale(stockAndSalesDetails);

        const setSummaryVal = res.data["summary" + selectedType.label];
        console.log(
          "setSummaryVal ",
          setSummaryVal,
          res.data,
          selectedType.label
        );
        setSummary(setSummaryVal);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.error(err.response);
          logout();
          // ToastError("Token has expired Please refresh");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const viewOrder = () => {
    setOrderList([]);

    if (!selectedCompany || !selectedCompany.value) {
      message.error("Please Select Company ");
      return;
    }
    const getToken = JSON.parse(localStorage.getItem("user-info"));
    const companyCode = companiesList.find(
      (data) => data.comCode === selectedCompany.value
    ).comCode;

    setIsLoading(true);
    axios
      .post(
        env.REACT_APP_BASE_URL + "/StockAndSales/OrderList/",
        {
          wclientid: getToken.clientid,
          compCode: companyCode,
          monthly: selectedType.value,
        },
        { headers: { Authorization: `Bearer ${getToken.token}` } }
      )
      .then(function (result) {
        const orderListData = result.data;
        console.log("orderlist data", orderListData);
        let ordernumber = orderListData.map(function (data, i) {
          return data.orderNo;
        });

        console.log(ordernumber);
        setOrderNo(ordernumber);
        setOrderList(result.data);
      })
      .catch((err) => {
        message.error("Failed To Load Order List");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const orders = (orderNumber) => {
    const getToken = JSON.parse(localStorage.getItem("user-info"));
    const companyCode = companiesList.find(
      (data) => data.comCode === selectedCompany.value
    ).comCode;

    setIsOrderDetailsLoading(true);
    axios
      .post(
        env.REACT_APP_BASE_URL + "/StockAndSales/OrderDetails/",
        {
          wClientId: getToken.clientid,
          compCode: companyCode,
          orderNo: orderNumber,
        },
        { headers: { Authorization: `Bearer ${getToken.token}` } }
      )
      .then(function (result) {
        const OrderDetails = result.data;
        setOrderDetails(OrderDetails);
        console.log(OrderDetails);
      })
      .catch((err) => {
        message.error("Fail to Load Orders");
      })
      .finally(() => {
        setIsOrderDetailsLoading(false);
      });
  };

  useEffect(() => {
    let isComponentMounted = true;
    console.log("Test");
    isComponentMounted && showDataList();
    return () => {
      isComponentMounted = false;
    };
  }, [showDataList]);

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      navigate("/");
      message.info("Please Login");
      return;
    }

    return () => {};
  }, []);

  // console.log("state data", data);

  useEffect(() => {
    console.log(selectedCompany);
    return () => {};
  }, [selectedCompany]);

  useEffect(() => {
    console.log(selectedType);
    return () => {};
  }, [selectedType]);

  useEffect(() => {
    console.log("type", selectedType.value);
    return () => {};
  }, [selectedType]);

  // const orderDetailsPdf = () => {
  //   const companyName = companiesList.find(
  //     (data) => data.comCode === selectedCompany.value
  //   ).companyName;
  //   const doc = new jsPDF("l", "pt", "legal");
  //   doc.setFontSize(12);
  //   doc.text(`${clientname}`, 50, 19);

  //   doc.setFontSize(10);
  //   doc.text(`Supplier Name : ${"  "} ${orderDetails[0].supName}`, 50, 32);
  //   doc.text(
  //     `Order No :${"  "}${selectedOrderNo && selectedOrderNo.orderNo}`,
  //     50,
  //     45
  //   );
  //   doc.text(
  //     `Order Date:${"  "} ${selectedOrderNo && dateFormat(selectedOrderNo.orderDt, "dd-mm-yyyy")
  //     }`,
  //     50,
  //     58
  //   );
  //   doc.autoTable({ html: "#orderlist", startY: 70 });
  //   doc.text(
  //     `www.amruthstockandsale.com ${" "} ${dateFormat(now, "isoDateTime")}`,
  //     550,
  //     550
  //   );
  //   // doc.autoTable({ startX: 120, startY: 150 });
  //   doc.save("orderDetails.pdf");
  // };

  return (
    <>
      <div className="">
        <Row>
          <div className="col-md-4 offset-md-4 ">
            <h3 style={{ textAlign: "center", fontSize: "25px", margin: 0 }}>
              {website}
            </h3>
          </div>
        </Row>
        <div className="row mx-0" style={{ padding: 0 }}>
          <div className="col-md-4">
            <h6 className="pl-2 pt-2">Welcome {username}</h6>
          </div>
          <div className="col-md-4 offset-md-4 d-flex justify-content-end ">
            <Button
              style={{ width: 50, marginBottom: 10 }}
              onClick={logout}
              type="primary"
              icon={<PoweroffOutlined />}
            ></Button>
          </div>
        </div>
      </div>

      <div
        className="row "
        style={{ margin: "0px", backgroundColor: "#e9e9e9" }}
      >
        <div
          className="col-md-12"
          style={{
            backgroundColor: "#fff",

            // padding: "15px",
            borderRadius: "2px",
            marginTop: "12px",
            // margin: "5px",
            // marginLeft: "16px",
            marginBottom: "10px",
          }}
        >
          <p className="Header1">
            {clientname}, {place}
          </p>
          <form>
            <div className="row">
              <div className=" col-md-6 pl-2" style={{ fontSize: fontSize }}>
                <div className="form-group">
                  <p style={{ fontSize: "12px", margin: "0" }}> Company name</p>
                  <Select
                    options={companiesList.map((data) => {
                      return {
                        label: data.companyName,
                        value: data.comCode,
                      };
                    })}
                    value={selectedCompany}
                    onChange={handleChangeCompany}
                    id="compname"
                  ></Select>
                  {companyIsLoading && <Loader />}
                </div>
              </div>

              <div
                className="form-group col-md-6 "
                style={{ fontSize: fontSize }}
              >
                <div className="form-group">
                  <p style={{ fontSize: "12px", margin: "0" }}> Period</p>
                  <Select
                    id="type"
                    options={typeOptions.map((item) => {
                      return {
                        label: item.label,
                        value: item.value,
                      };
                    })}
                    // defaultValue={{ label: "running", value: 1 }}
                    // defaultValue={typeOptions.label}
                    value={selectedType}
                    onChange={handleChangeType}
                  ></Select>
                </div>
              </div>
            </div>
            {selectedCompany && (
              <Row>
                <Col md={6} sm={6} xs={6}>
                  <div className={"text-right"}>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm   mb-2 "
                      style={{ width: 160 }}
                      onClick={() => {
                        // setSelectedCompany();
                        if (!selectedCompany || !selectedCompany.value) {
                          message.error("Please Select Company ");
                          return;
                        }
                        showSummary();
                        setVieworder(false);
                        setShowData(true);
                      }}
                    >
                      Stock and Sales
                    </button>
                  </div>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm  "
                    style={{ width: 120 }}
                    onClick={() => {
                      if (!selectedCompany || !selectedCompany.value) {
                        message.error("Please Select Company ");
                        return;
                      }
                      viewOrder();
                      setVieworder(true);
                      setShowData(false);
                      setOrderDetails([]);
                      setDetailedOrderList(false);
                      setSelectedOrderNo(null);
                    }}
                  >
                    View Order
                  </button>
                </Col>
              </Row>
            )}
          </form>
        </div>
        <Col md={12}>
          {companiesList && selectedCompany && showData && (
            <Row>
              {isLoading && <Loader />}
              <div
                className="col-md-12 "
                style={{
                  borderRadius: "4px",
                  marginTop: "10px",
                  backgroundColor: "#fcfcfc",
                  height: "84px",
                }}
              >
                <div className="row">
                  <div
                    className="col-lg-12 p -0"
                    style={{
                      backgroundColor: "#fcfcfc",
                    }}
                  >
                    <div className="row">
                      {/* <div
                        className="col-lg-6 "
                        style={{ backgroundColor: "" }}
                      > */}
                      {/* <TextSmall>Company Name</TextSmall>
                        <br /> */}
                      {/* <Text>{selectedCompany.label}</Text> */}
                      {/* </div> */}
                      <div className="col-lg-8" style={{ backgroundColor: "" }}>
                        <br />
                        <TextSmall>From</TextSmall>
                        <Text>{dateFrom}</Text> <TextSmall>to</TextSmall>
                        <Text>{dateTo}</Text>
                        <br />
                      </div>
                      {/* <div className="col-lg-2" style={{ backgroundColor: "" }}>
                        <br />
                        <Text>{dateTo}</Text>
                        <br />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {summary && Object.keys(summary).length > 0 && (
                <SummaryTable summary={summary} />
              )}

              <div
                className="col-lg-12 p-0 "
                style={{
                  // padding: "10px",
                  borderRadius: "4px",
                  marginTop: "2px",
                }}
              >
                <div
                  className="col-lg-12"
                  style={{
                    backgroundColor: "#fcfcfc",
                    // padding: "10px",
                    borderRadius: "4px",
                    marginLeft: "2px",
                  }}
                >
                  {stockAndSale && Object.keys(summary).length > 0 && (
                    <StockAndSalesTable
                      stockAndSale={stockAndSale}
                      companiesList={companiesList}
                      dateFrom={dateFrom}
                      dateTo={dateTo}
                      dateFormat={dateFormat}
                      now={now}
                      selectedCompany={selectedCompany}
                      clientname={clientname}
                    />
                  )}
                </div>
              </div>
            </Row>
          )}
        </Col>

        {vieworder && (
          <>
            <div
              className="col-md-12"
              style={{
                backgroundColor: "#fcfcfc",
                // marginLeft: "16px",
                marginTop: "10px",
                marginBottom: "12px",
                borderRadius: "4px",
                textAlign: "center",
                alignItems: "center",
                justifySelf: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h4 style={{ color: "#007bff" }}>Order List</h4>
              {isLoading && <Loader />}
              <Table
                bordered
                hover
                style={{
                  width: 400,
                  borderRadius: 5,
                }}
              >
                <thead
                  style={{
                    fontSize: fontSize,
                    color: "#3F1FB7",
                    backgroundColor: "#86DEFF",
                    textAlign: "right",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                >
                  <tr
                    style={{
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    }}
                  >
                    <th style={{ padding: "5px" }}>Order No.</th>
                    <th style={{ padding: "5px" }}>Order Date</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "right" }}>
                  {orderList.map((data, i) => (
                    <tr key={i}>
                      <td
                        onClick={() => {
                          setDetailedOrderList(true);
                          orders(data.orderNo);
                          setSelectedOrderNo(data);
                          // setOrderNo(ordernumber);
                        }}
                        style={{
                          padding: "5px",
                          fontSize: fontSize,
                          cursor: "pointer",
                          color: "blue",
                        }}
                      >
                        <b style={{}}>
                          <u>{data.orderNo}</u>
                        </b>
                      </td>
                      <td style={{ padding: "5px", fontSize: fontSize }}>
                        {dateFormat(data.orderDt, "dd-mm-yyyy")}
                        {/* {new Date(data.orderDt)} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}

        {vieworder && detailedOrderList && (
          <>
            <OrderDetails
              isOrderDetailsLoading={isOrderDetailsLoading}
              orderDetails={orderDetails}
              dateFormat={dateFormat}
              selectedOrderNo={selectedOrderNo}
              selectedCompany={selectedCompany}
              companiesList={companiesList}
              clientname={clientname}
              now={now}
            />
          </>
        )}
        <section className="spacer">
          <div></div>
        </section>

        <Footer website={website} />
      </div>
    </>
  );
}

export default HomePage;
